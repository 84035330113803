<template>
	<div class="page">
		<div style="display: flex;flex-direction: column; justify-content: center;" class="login-card">
			<div  class="inp-card">
				<div style="font-size: 20px; font-weight: 800; text-align: center;">大润电子</div>
				<div class="inp-item" style="margin-top: 40px;">
					<img class="icon" src="../assets/user.png" /> <input v-model="username" placeholder="请输入用户名"
						class="my-inp" />
				</div>
				<div class="inp-item" style="margin-top: 20px;">
					<img class="icon" style="width: 18px; height: 18px;" src="../assets/pwd.png" /> <input
						v-model="password" type="password" placeholder="请输入密码" class="my-inp" />
				</div>
				<div @click="login" class="login-btn">
					登录
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				username: "",
				password: ""
			}
		},
		
		mounted:function(){
			if(localStorage.getItem("username") && localStorage.getItem("password")){
				this.username = localStorage.getItem("username")
				this.password = localStorage.getItem("password")
			}
			
		},

		methods: {
			login: function() {
				var that = this
				this.$store.dispatch("Login", {
					username: that.username,
					password: that.password,
				}).then((isTrue) => {
					if (isTrue) {
						localStorage.setItem("username",that.username)
						localStorage.setItem("password",that.password)
						that.$router.push({
							path: "/home"
						})
					} else {
						that.$notify.error({
							title: '错误',
							message: '账号或密码错误！'
						});
					}
				})
			}
		}


	}
</script>

<style scoped="scoped">
	.page {
		height: 100vh;
		width: 100vw;
		background-image: url(../assets/bg.jpg);
		background-size: cover;
		position: relative;
		background-repeat: no-repeat;
		animation:bg 2s infinite;
		animation-iteration-count: 1;
	}
	
	@keyframes bg{
		from{
			background-size: 110%;
		}
		to{
			background-size: 100%;
		}
	}
	
	@keyframes silde{
		from{
			right: -100%;
		}
		to{
			right: 0;
		}
	}
	
	@keyframes show{
		from{
			margin-top: 0;
			opacity: 0;
		}
		to{
			margin-top: -200px;
			opacity: 1;
		}
	}
	
	.inp-card{
		margin-top: -200px;
		animation:show 2.5s linear 1s;
		animation-iteration-count: 1;
	}
	
	.login-card {
		position: absolute;
		width: 400px;
		top: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(241,243,244, 1);
		box-shadow: 0 0 20px yellow;
		padding: 40px 40px;
		padding-bottom: 60px;
		box-sizing: border-box;
		animation:silde 2s;
		animation-iteration-count: 1;
	}

	.my-inp {
		border: none;
		background: none;
		outline: none;
		color: black;
		flex-grow: 5;
	}

	.inp-item {
		border-bottom: 1px solid rgba(117,117,117,.2);
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 5px;
		padding-bottom: 5px;
	}

	.icon {
		width: 16px;
		height: 16px;
		margin-right: 10px;
	}

	.my-inp::placeholder {
		color: black;
	}

	.login-btn {
		height: 40px;
		color: white;
		line-height: 40px;
		border-radius: 4px;
		margin-top: 30px;
		text-align: center;
		cursor: pointer;
		background-image: linear-gradient(to bottom right, rgb(88, 147, 246), rgb(38, 51, 210));
	}
	
	.login-btn:hover{
		font-weight: bolder;
		background-image: linear-gradient(to bottom right, rgb(98, 157, 255), rgb(48, 61, 220));
	}

	.my-inp:-webkit-autofill,
	.my-inp:-webkit-autofill:hover,
	.my-inp:-webkit-autofill:focus,
	.my-inp:-webkit-autofill:active {
		-webkit-transition-delay: 99999s;
		-webkit-transition: color 99999s ease-out, background-color 99999s ease-out;
	}
</style>
